const main = process.env.REACT_APP_API_URL;
const dev = process.env.REACT_APP_API_URL;

let mainAPI;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev
    mainAPI = `${dev}/api`;
} else {
    // prod
    mainAPI = `${main}/api`;
}

const xiaohongshuUrl = `${mainAPI}/xiaohongshu`;
const douyinUrl = `${mainAPI}/douyin`;
const authUrl = `${mainAPI}/auth`;
const adminUrl = `${mainAPI}/admin`;
const supportUrl = `${mainAPI}/support`;

export const xiaohongshuUrls = ({pk, note_pk, uid} = {}) => {
    // 주의: 마지막 '/' 반드시 붙여야한다!
    return {
        // 샤홍슈 Influencer
        influencers: `${xiaohongshuUrl}/`,
        influencer: `${xiaohongshuUrl}/${pk}/`,
        fanTrackers: `${xiaohongshuUrl}/${pk}/fantrackers/`,
        noteStats: `${xiaohongshuUrl}/${pk}/notestats/`,
        fanStats: `${xiaohongshuUrl}/${pk}/fanstats/`,
        notes: `${xiaohongshuUrl}/${pk}/notes/`,
        noteDetails: `${xiaohongshuUrl}/notes/${note_pk}/`,
        translate: `${xiaohongshuUrl}/translate/`,
        like: `${xiaohongshuUrl}/like/`,
        cart: `${xiaohongshuUrl}/cart/`,
        notesRequests: `${xiaohongshuUrl}/notes/requests/`,
        notesExport: `${xiaohongshuUrl}/notes/export/`,

        // Hot
        hotContents: `${xiaohongshuUrl}/hot/notes/`,
        hotContentRaking: `${xiaohongshuUrl}/notes/normal`,
        hotPromotionRanking: `${xiaohongshuUrl}/notes/promotion`,
        hotInfluencers: `${xiaohongshuUrl}/hot/influencers/`,

        /// brand-analysis
        brands: `${xiaohongshuUrl}/brands/`,
        brandsSearch: `${xiaohongshuUrl}/brands/search/`,
        brandProfile: `${xiaohongshuUrl}/brands/${pk}/`,
        brandPublishes: `${xiaohongshuUrl}/brands/${pk}/publishes/`,
        brandCollabNotes: `${xiaohongshuUrl}/brands/${pk}/notes/collab/`,
        brandSelfNotes: `${xiaohongshuUrl}/brands/${pk}/notes/self/`,

        // Campaigns
        campaigns: `${xiaohongshuUrl}/campaigns/`,
        campaign: `${xiaohongshuUrl}/campaigns/${uid}/`,
        campaignInfluencers: `${xiaohongshuUrl}/campaigns/influencers/`,
        campaignPublications: `${xiaohongshuUrl}/campaigns/${uid}/publications/`,
        campaignKeywords: `${xiaohongshuUrl}/campaigns/${uid}/keywords/`,
        campaignRanks: `${xiaohongshuUrl}/campaigns/${uid}/ranks/`,
        campaignSummaryTrackers: `${xiaohongshuUrl}/campaigns/${uid}/summary/note_trackers/`,
        campaignPublicationTrackers: `${xiaohongshuUrl}/campaigns/${uid}/publications/${note_pk}/note_trackers/`,
        campaignPublicationComments: `${xiaohongshuUrl}/campaigns/${uid}/publications/${note_pk}/note_comments/`,

        // Core/Admin
        users: `${adminUrl}/users/`,
        change_grade: `${adminUrl}/users/change/grade/`,
        logs: `${adminUrl}/logs/`,

        // Core/Support
        supportAsk: `${supportUrl}/ask/`,

        // Core/Auth
        validate_unique: `${authUrl}/validate/`,
        validate_password: `${authUrl}/validate/password/`,
        user: `${authUrl}/user/`,
        register: `${authUrl}/register/`,
        login: `${authUrl}/login/`,
        logout: `${authUrl}/logout/`,
        resetCode: `${authUrl}/verify/reset_code/`,
        resetPasswordCode: `${authUrl}/verify/reset_code/reset_password/`,
    }
};

export const douyinUrls = ({pk, note_pk, uid} = {}) => {
    // 주의: 마지막 '/' 반드시 붙여야한다!
    return {
        // 도우인 Influencer
        influencers: `${douyinUrl}/`,
        hotinfluencers: `${douyinUrl}/hot/influencers`,
        influencer: `${douyinUrl}/${pk}/`,
        fanstats: `${douyinUrl}/${pk}/fanstats/`,
        fantrackers: `${douyinUrl}/${pk}/fantrackers/`,
        hotcomments: `${douyinUrl}/${pk}/hotcomments/`,
        videos: `${douyinUrl}/${pk}/videos/`,
        videostats: `${douyinUrl}/${pk}/videostats/`,
        videoranks: `${douyinUrl}/videos/ranks/`,
        videoRequests: `${douyinUrl}/video/requests/`,
    }
};

export const XHSPrefix = '/xiaohongshu';
export const DouyinPrefix ='/douyin';
export const AdminPrefix = '/admin';
export const SupportPrefix = '/support';

export const appUrls = ({pk, note_pk, uid} = {}) => {
    return {
        // HOME
        home: '/',

        // CS
        SupportPrefix: SupportPrefix,
        supportFAQ: `${SupportPrefix}/faq`,
        supportAsk: `${SupportPrefix}/ask`,

        // ERRORS
        error404: '/errors/404',
        error500: '/errors/500',

        // AUTH
        login: '/auth/login',

        // SHARED LINK
        sharedDashboard: `/shared/dashboard/${uid}`,

        // ADMIN
        AdminPrefix: AdminPrefix,
        users: `${AdminPrefix}/users`,
        logs: `${AdminPrefix}/logs`,

        // XHSRoutes
        XHSPrefix: XHSPrefix,
        XHSMCNs: `${XHSPrefix}/mcns`,
        XHSBrandAnalysis: `${XHSPrefix}/analysis/brands`,
        XHSKeywordAnalysis: `${XHSPrefix}/analysis/keywords`,
        XHSNoteTracker: `${XHSPrefix}/notes/requests`,

        XHSCampaignLineup: `${XHSPrefix}/campaigns/lineup`,
        XHSCampaigns: `${XHSPrefix}/campaigns`,
        XHSCampaignRequest: `${XHSPrefix}/campaigns/request`,
        XHSCampaignRequestComplete: `${XHSPrefix}/campaigns/request/complete`,
        XHSCampaignDashboard: `${XHSPrefix}/campaigns/${uid}/dashboard`,
        XHSCampaignEdit: `${XHSPrefix}/campaigns/${uid}/edit`,

        XHSHotContents: `${XHSPrefix}/hot/contents`,
        XHSHotInfluencers: `${XHSPrefix}/hot/influencers`,

        // XHSRoutes/XHSInfluencerRoutes
        XHSInfluencers: `${XHSPrefix}/influencers`,
        XHSInfluencerDetail: `${XHSPrefix}/influencers/${pk}`,
        XHSInfluencerNoteDetail: `${XHSPrefix}/influencers/${pk}/notes/${note_pk}`,
        XHSInfluencerNoteDetail2: `${XHSPrefix}/influencers/notes/${note_pk}`,

        // DouyinRoutes
        DouyinHotInfluencers: `${DouyinPrefix}/hot/influencers`,
        DouyinHotContents: `${DouyinPrefix}/hot/contents`,
        DouyinInfluencers: `${DouyinPrefix}/influencers`,
        DouyinInfluencerDetail: `${DouyinPrefix}/influencers/${pk}`,
        DouyinVideoTracker: `${DouyinPrefix}/video/requests`,
    };
};